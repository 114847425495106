import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
  useLocation
} from 'react-router-dom'
import { ToastContainer, Zoom } from 'react-toastify'

// styles
import './App.css'
import 'react-toastify/dist/ReactToastify.css'

// components
import Container from './components/Container'
import Navbar from './components/Navbar'
import Disclaimer from './components/Disclaimer'
import ThemeSelector from './components/ThemeSelector'

// page components
import Create from './pages/create/Create'
import Home from './pages/home/Home'
import Recipe from './pages/recipe/Recipe'
import Update from './pages/update/Update'
import Search from './pages/search/Search'
import Login from './pages/login/Login'
import Register from './pages/register/Register'
import MyRecipes from './pages/my-recipes/MyRecipes'

// hooks
import { useTheme } from './hooks/useTheme'
import { useAuthContext } from './hooks/useAuthContext'

const App = () => {
  const { mode } = useTheme()
  const { authIsReady, user } = useAuthContext()

  const AuthRoute = ({ type }) => {
    let location = useLocation()

    if (type === 'guest' && user && !location.state) {
      return <Navigate to="/" />
    }
    if (type === 'private' && !user) {
      return <Navigate to="/login" replace state={{ from: location }} />
    }

    return <Outlet />
  }

  return (
    <div className={`App ${mode}`}>
      {!authIsReady && (
        <div className="app-loading">
          <span className="spinner-border"></span>
        </div>
      )}
      {authIsReady && (
        <Router>
          <div className="container-vertical">
            <div className="container-vertical-content">
              <Navbar />
              <Container>
                <ThemeSelector />

                <Routes>
                  <Route exact path="/" element={<Home />} />
                  <Route path="/search" element={<Search />} />
                  <Route path="/recipe/:id" element={<Recipe />} />

                  <Route element={<AuthRoute type="guest" />}>
                    <Route path="/login" element={<Login />} />
                  </Route>
                  <Route element={<AuthRoute type="guest" />}>
                    <Route path="/register" element={<Register />} />
                  </Route>

                  <Route element={<AuthRoute type="private" />}>
                    <Route path="/create" element={<Create />} />
                  </Route>
                  <Route element={<AuthRoute type="private" />}>
                    <Route path="/recipe/edit/:id" element={<Update />} />
                  </Route>
                  <Route element={<AuthRoute type="private" />}>
                    <Route path="/my-recipes" element={<MyRecipes />} />
                  </Route>
                </Routes>
              </Container>
            </div>
          </div>
          <Disclaimer />
          <ToastContainer
            position="top-center"
            autoClose={3000}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            theme={mode}
            transition={Zoom}
            closeButton={true}
            style={{ fontWeight: '300' }}
          />
        </Router>
      )}
    </div>
  )
}

export default App
