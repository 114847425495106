import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyBGaWnzfNFkotXogUFAayHfsWiobMO9tvc',
  authDomain: 'youdev-cooking-app.firebaseapp.com',
  projectId: 'youdev-cooking-app',
  storageBucket: 'youdev-cooking-app.appspot.com',
  messagingSenderId: '600814039621',
  appId: '1:600814039621:web:3f88496a1e285d1f07292c'
}

// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()

// timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectAuth, timestamp }
