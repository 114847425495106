import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import SimpleMdeReact from 'react-simplemde-editor'
import 'easymde/dist/easymde.min.css'

// styles
import './Update.css'

// services
import { getRecipeById, updateRecipe } from '../../services/recipeService'

// hooks
import { useTheme } from '../../hooks/useTheme'

const Input = styled.input`
  &:focus {
    border-color: ${(props) => props.theme.color} !important;
  }
`
const Textarea = styled.textarea`
  &:focus {
    border-color: ${(props) => props.theme.color} !important;
  }
`

const Update = () => {
  const { color } = useTheme()

  const { id } = useParams()
  const ingredientInput = useRef(null)
  const naviagate = useNavigate()

  const initialValues = {
    title: '',
    method: '',
    cookingTime: '',
    newIngredient: '',
    ingredients: []
  }
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [isEdited, setIsEdited] = useState(false)

  const [editRecipe, setEditRecipe] = useState(null)
  const [editIsPending, setEditIsPending] = useState(false)
  const [editError, setEditError] = useState(null)

  // edit data (edited once when the page loads)
  const editData = useCallback(() => {
    setEditIsPending(true)

    getRecipeById(id).then((doc) => {
      if (doc.exists) {
        setEditRecipe(doc.data())
        setFormValues({
          title: doc.data().title || '',
          method: doc.data().method || '',
          cookingTime: doc.data().cookingTime || '',
          newIngredient: '',
          ingredients: doc.data().ingredients || []
        })
        setEditIsPending(false)
      } else {
        setEditError('Could not find that recipe!')
        setEditIsPending(false)
      }
    })

    setIsEdited(true)
  }, [id])

  // update data
  const updateData = useCallback(async () => {
    try {
      await updateRecipe(id, {
        title: formValues.title.trim(),
        ingredients: formValues.ingredients,
        method: formValues.method.trim(),
        cookingTime: formValues.cookingTime,
        titleLower: formValues.title.trim().toLowerCase()
      })

      naviagate(`/recipe/${id}`)

      toast.success('Recipe updated successfully!')
    } catch (err) {
      console.log(err)
    }
  }, [
    id,
    formValues.title,
    formValues.ingredients,
    formValues.method,
    formValues.cookingTime,
    naviagate
  ])

  const handleChange = (e) => {
    let { name, value } = e.target

    if (name === 'newIngredient') value = value.toLowerCase()
    if (name === 'cookingTime' && value && !value.match(/^[0-9]*$/)) return

    setFormValues({ ...formValues, [name]: value })
  }

  const validate = (values) => {
    const errors = {}

    if (!values.title.trim()) errors.title = 'Recipe title is required.'
    if (!values.method.trim()) errors.method = 'Recipe method is required.'
    if (!values.cookingTime) errors.cookingTime = 'Cooking time is required.'

    return errors
  }

  const onChangeMethod = (value) => {
    setFormValues({ ...formValues, method: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setFormErrors(validate(formValues))
    setIsSubmit(true)
  }

  const handleAdd = (e) => {
    e.preventDefault()

    const ing = formValues.newIngredient.trim()
    if (ing && !formValues.ingredients.includes(ing)) {
      setFormValues((prevFormValues) => {
        return {
          ...prevFormValues,
          ingredients: [...prevFormValues.ingredients, ing.toLowerCase()]
        }
      })
    }
    setFormValues((prevFormValues) => {
      return { ...prevFormValues, newIngredient: '' }
    })
    ingredientInput.current.focus()
  }

  const handleRemove = (e, ing) => {
    e.preventDefault()

    setFormValues((prevFormValues) => {
      return {
        ...prevFormValues,
        ingredients: [...prevFormValues.ingredients.filter((i) => i !== ing)]
      }
    })
  }

  useEffect(() => {
    if (!isEdited) {
      editData()
    }

    if (Object.keys(formErrors).length === 0 && isSubmit) {
      updateData()
    }
  }, [formErrors, isSubmit, isEdited, editData, updateData])

  return (
    <>
      {editIsPending && (
        <div className="loading">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {editError && <p className="error">{editError}</p>}
      {editRecipe && (
        <div className="create">
          <h2 className="page-title">Edit Recipe</h2>
          <form onSubmit={handleSubmit} noValidate>
            <label>
              <div className="span">Recipe Title *</div>
              <Input
                type="text"
                className={`${formErrors.title && 'error-field'}`}
                name="title"
                value={formValues.title}
                onChange={handleChange}
                required
              />
              {formErrors.title && (
                <em className="error-feedback">{formErrors.title}</em>
              )}
            </label>
            <label htmlFor="newIngredient">
              <div className="span">Recipe Ingredients</div>
            </label>
            <div className="ingredients">
              <Input
                type="text"
                id="newIngredient"
                name="newIngredient"
                value={formValues.newIngredient}
                onChange={handleChange}
                ref={ingredientInput}
              />
              <button
                className="btn"
                onClick={handleAdd}
                style={{ backgroundColor: color }}
              >
                + add
              </button>
            </div>
            <p style={{ fontWeight: '300' }}>
              {formValues.ingredients.length > 0 && 'Current ingredients: '}
              {formValues.ingredients.length > 0 &&
                formValues.ingredients.map((ing) => (
                  <em
                    className="ing-tag"
                    key={ing}
                    onClick={(e) => handleRemove(e, ing)}
                  >
                    {ing.toLowerCase()} &times;
                  </em>
                ))}
            </p>
            <label>
              <div className="span">Recipe Method *</div>
              {/* <Textarea
                type="text"
                className={`${formErrors.method && 'error-field'}`}
                name="method"
                value={formValues.method}
                onChange={handleChange}
                rows={6}
                required
              /> */}
              <SimpleMdeReact
                value={formValues.method}
                onChange={onChangeMethod}
              />
              {formErrors.method && (
                <em className="error-feedback">{formErrors.method}</em>
              )}
            </label>
            <label>
              <div className="span">Cooking Time (minutes) *</div>
              <Input
                type="text"
                className={`${formErrors.cookingTime && 'error-field'}`}
                name="cookingTime"
                value={formValues.cookingTime}
                onChange={handleChange}
                required
              />
              {formErrors.cookingTime && (
                <em className="error-feedback">{formErrors.cookingTime}</em>
              )}
            </label>
            <button className="btn" style={{ backgroundColor: color }}>
              Update Recipe
            </button>
          </form>
        </div>
      )}
    </>
  )
}

export default Update
