// now mode (dark | light)
const nowMode = () => {
  const hours = new Date().getHours()
  const isDayTime = hours > 6 && hours < 20

  return isDayTime ? 'light' : 'dark'
}

// toggle password
const togglePassword = (passwordField, eyeIcon) => {
  if (passwordField.type === 'password') {
    passwordField.type = 'text'
    eyeIcon.classList.toggle('close')
  } else {
    passwordField.type = 'password'
    eyeIcon.classList.toggle('close')
  }
}

// string to array
const stringToArray = (str) => {
  return str.trim().split(' ')
}

export { nowMode, togglePassword, stringToArray }
