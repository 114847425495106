import React from 'react'

// styles
import './Pagination.css'

const Pagination = ({ page, pages, showPrevious, showNext }) => {
  return (
    <div className="paging">
      <div className="paging-carrier">
        <div>
          {page === 1 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              stroke=""
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="arcs"
            >
              <path d="M15 18l-6-6 6-6"></path>
            </svg>
          ) : (
            <a
              title="Previous Page"
              href={`#${page - 1}`}
              onClick={showPrevious}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
                fill="none"
                stroke=""
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="arcs"
              >
                <path d="M15 18l-6-6 6-6"></path>
              </svg>
            </a>
          )}
        </div>
        <span className="paging-control">
          <span className="fw-light">{page}</span> / {pages}
        </span>
        <div>
          {page === pages ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              stroke=""
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="arcs"
            >
              <path d="M9 18l6-6-6-6"></path>
            </svg>
          ) : (
            <a title="Next Page" href={`#${page + 1}`} onClick={showNext}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
                fill="none"
                stroke=""
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="arcs"
              >
                <path d="M9 18l6-6-6-6"></path>
              </svg>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default Pagination
