import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

// styles
import './Search.css'

// services
import { searchForRecipe } from '../../services/recipeService'

// components
import RecipeList from '../../components/RecipeList'

const Search = () => {
  const [data, setData] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState(null)

  const [search] = useSearchParams()

  const term = search.get('q')

  useEffect(() => {
    setIsPending(true)

    const unsubscribe = searchForRecipe(
      term,
      (snapshot) => {
        if (snapshot.empty) {
          setError('No recipes found!')
          setIsPending(false)
        } else {
          let results = []
          snapshot.docs.forEach((doc) => {
            results.push({ id: doc.id, ...doc.data() })
          })
          setData(results)
          setIsPending(false)
        }
      },
      (err) => {
        setError(err.message)
        setIsPending(false)
      }
    )

    return () => unsubscribe()
  }, [term])

  return (
    <div className="search">
      <h3 className="search-title">
        Recipes including “<strong>{term}</strong>”
      </h3>
      {isPending && (
        <div className="loading">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {error && <p className="error">{error}</p>}
      {data && <RecipeList recipes={data} />}
    </div>
  )
}

export default Search
