import React from 'react'

// styles
import './Container.css'

const Container = ({ children }) => {
  return <div className="container">{children}</div>
}

export default Container
