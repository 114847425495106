import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Markdown from 'react-markdown'

// styles
import './Recipe.css'

// icons
import { penIcon, trashIcon } from '../../icons'

// services
import { getRecipeById, removeRecipe } from '../../services/recipeService'

// components
import ConfirmBox from '../../components/ConfirmBox'

// context
import { useAuthContext } from '../../hooks/useAuthContext'

const Recipe = () => {
  const [recipe, setRecipe] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState(null)
  const [show, setShow] = useState(false)
  const [selectedRecipe, setSelectedRecipe] = useState(null)

  const { user } = useAuthContext()

  const navigate = useNavigate()
  const { id } = useParams()

  const handleHide = () => setShow(false)
  const handleShow = (id) => {
    setSelectedRecipe(id)
    setShow(true)
  }

  const handleEdit = (id) => {
    navigate(`/recipe/edit/${id}`)
  }

  const handleRemove = async () => {
    setShow(false)
    await removeRecipe(selectedRecipe)

    navigate('/')

    toast.success('Recipe removed successfully!')
  }

  useEffect(() => {
    setIsPending(true)

    getRecipeById(id).then((doc) => {
      if (doc.exists) {
        setRecipe(doc.data())
        setIsPending(false)
      } else {
        setError('Could not find that recipe!')
        setIsPending(false)
      }
    })
  }, [id])

  return (
    <>
      {isPending && (
        <div className="loading">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {error && <p className="error">{error}</p>}
      {recipe && (
        <>
          <div className="recipe">
            <h2 className="page-title">{recipe.title}</h2>
            <p className="text-center">{recipe.cookingTime} minutes to cook.</p>
            <div className="text-center">
              <ul className="ingredients">
                {recipe.ingredients.map((ing) => (
                  <li key={ing}>{ing}</li>
                ))}
              </ul>
            </div>
            {/* <p className="method">{recipe.method}</p> */}
            <div className="method">
              <Markdown children={recipe.method} />
            </div>
            {user && user.uid === recipe.uid && (
              <>
                <img
                  src={penIcon}
                  className="action pen"
                  alt="edit"
                  title="edit"
                  onClick={() => handleEdit(id)}
                />
                <img
                  src={trashIcon}
                  className="action trash"
                  alt="remove"
                  title="remove"
                  onClick={() => handleShow(id)}
                />
              </>
            )}
          </div>
          <ConfirmBox
            title="Delete Recipe"
            message="Are you sure you wish to remove this recipe?"
            show={show}
            handleHide={handleHide}
            handleAction={handleRemove}
          />
        </>
      )}
    </>
  )
}

export default Recipe
