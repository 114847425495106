import React from 'react'

// styles
import './Disclaimer.css'

const Disclaimer = () => {
  return (
    <div className="disclaimer">
      <p className="copyright">Youcef Developer &copy; 2022</p>
      <p className="keywords">Technologies</p>
      <span className="badge">javascript</span>
      {/* <span className="badge">html/css</span> */}
      <span className="badge">react</span>
      <span className="badge">context api</span>
      <span className="badge">firebase</span>
    </div>
  )
}

export default Disclaimer
