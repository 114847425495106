import React from 'react'

import './Skeleton.css'

const SkeletonElement = ({ type, width, space }) => {
  const classes = `skeleton ${type} ${width} ${space}`

  return <div className={classes}></div>
}

SkeletonElement.defaultProps = {
  width: '',
  space: ''
}

export default SkeletonElement
