import React from 'react'

// styles
import './ThemeSelector.css'

// icons
import { sunIcon } from '../icons'

// hooks
import { useTheme } from '../hooks/useTheme'

// theme colors
const themeColors = ['#8A39E1', '#E60965', '#219F94']

const ThemeSelector = () => {
  const { changeColor, color: activeColor, changeMode, mode } = useTheme()

  const handleChangeColor = (e, color) => {
    e.preventDefault()

    changeColor(color)
    document.getElementById('favicon').href = `/favicon-${color.replace(
      '#',
      ''
    )}.ico`
  }

  const toggleMode = () => {
    changeMode(mode === 'dark' ? 'light' : 'dark')
  }

  return (
    <div className="theme-selector">
      <div className="mode-toggle">
        <img
          src={sunIcon}
          className="mode-icon"
          alt="mode"
          onClick={toggleMode}
          style={{ filter: mode === 'dark' ? 'invert(100%)' : 'invert(20%)' }}
        />
      </div>
      <div className="theme-buttons">
        {themeColors.map((color) => (
          <span
            key={color}
            className={`${color === activeColor && 'active'}`}
            onClick={(e) => handleChangeColor(e, color)}
            style={{ backgroundColor: color }}
          />
        ))}
      </div>
    </div>
  )
}

export default ThemeSelector
