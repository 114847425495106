import { createContext, useReducer } from 'react'
import { ThemeProvider as StyleProdivder } from 'styled-components'

import { nowMode } from '../utils'

export const ThemeContext = createContext()

const themeReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_COLOR':
      return {
        ...state,
        color: action.payload.color
      }
    case 'CHANGE_MODE':
      return { ...state, mode: action.payload.mode }
    default:
      return state
  }
}

export const ThemeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(themeReducer, {
    color: '#8A39E1',
    mode: nowMode()
  })

  const changeColor = (color) => {
    dispatch({
      type: 'CHANGE_COLOR',
      payload: { color }
    })
  }

  const changeMode = (mode) => {
    dispatch({ type: 'CHANGE_MODE', payload: { mode } })
  }

  return (
    <ThemeContext.Provider value={{ ...state, changeColor, changeMode }}>
      <StyleProdivder theme={{ color: state.color }}>{children}</StyleProdivder>
    </ThemeContext.Provider>
  )
}
