import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import SimpleMdeReact from 'react-simplemde-editor'
import 'easymde/dist/easymde.min.css'

// styles
import './Create.css'

// services
import { addRecipe } from '../../services/recipeService'

// hooks
import { useTheme } from '../../hooks/useTheme'

// firabase
import { timestamp } from '../../firebase/config'

// context
import { useAuthContext } from '../../hooks/useAuthContext'

const Input = styled.input`
  &:focus {
    border-color: ${(props) => props.theme.color} !important;
  }
`
const Textarea = styled.textarea`
  &:focus {
    border-color: ${(props) => props.theme.color} !important;
  }
`

const Create = () => {
  const { color } = useTheme()
  const {
    user: { uid }
  } = useAuthContext()

  const initialValues = {
    title: '',
    method: '',
    cookingTime: '',
    newIngredient: '',
    ingredients: []
  }
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)

  const ingredientInput = useRef(null)
  const naviagate = useNavigate()

  // post data
  const postData = useCallback(async () => {
    try {
      const createdAt = timestamp.fromDate(new Date())
      const recipe = await addRecipe({
        title: formValues.title.trim(),
        ingredients: formValues.ingredients,
        method: formValues.method.trim(),
        cookingTime: formValues.cookingTime,
        titleLower: formValues.title.trim().toLowerCase(),
        createdAt,
        uid
      })

      if (recipe) naviagate(`/recipe/${recipe.id}`)

      toast.success('Recipe added successfully!')
    } catch (err) {
      console.log(err)
    }
  }, [
    formValues.title,
    formValues.ingredients,
    formValues.method,
    formValues.cookingTime,
    naviagate,
    uid
  ])

  const handleChange = (e) => {
    let { name, value } = e.target

    if (name === 'newIngredient') value = value.toLowerCase()
    if (name === 'cookingTime' && value && !value.match(/^[0-9]*$/)) return

    setFormValues({ ...formValues, [name]: value })
  }

  const validate = (values) => {
    const errors = {}

    if (!values.title.trim()) errors.title = 'Recipe title is required.'
    if (!values.method.trim()) errors.method = 'Recipe method is required.'
    if (!values.cookingTime) errors.cookingTime = 'Cooking time is required.'

    return errors
  }

  const onChangeMethod = useCallback((value) => {
    setFormValues((prevFormValues) => {
      return {
        ...prevFormValues,
        method: value
      }
    })
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()

    setFormErrors(validate(formValues))
    setIsSubmit(true)
  }

  const handleAdd = (e) => {
    e.preventDefault()

    const ing = formValues.newIngredient.trim()
    if (ing && !formValues.ingredients.includes(ing)) {
      setFormValues((prevFormValues) => {
        return {
          ...prevFormValues,
          ingredients: [...prevFormValues.ingredients, ing.toLowerCase()]
        }
      })
    }
    setFormValues((prevFormValues) => {
      return { ...prevFormValues, newIngredient: '' }
    })
    ingredientInput.current.focus()
  }

  const handleRemove = (e, ing) => {
    e.preventDefault()

    setFormValues((prevFormValues) => {
      return {
        ...prevFormValues,
        ingredients: [...prevFormValues.ingredients.filter((i) => i !== ing)]
      }
    })
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) postData()
  }, [formErrors, isSubmit, postData])

  return (
    <div className="create">
      <h2 className="page-title">Add a New Recipe</h2>

      {/* <pre>formValues: {JSON.stringify(formValues, undefined, 2)}</pre>
      <pre>formErrors: {JSON.stringify(formErrors, undefined, 2)}</pre>
      <pre>isSubmit: {JSON.stringify(isSubmit, undefined, 2)}</pre>
      <pre>data: {JSON.stringify(data, undefined, 2)}</pre>
      <pre>
        formErrors Length:{' '}
        {JSON.stringify(Object.keys(formErrors).length, undefined, 2)}
      </pre> */}

      <form onSubmit={handleSubmit} noValidate>
        <label>
          <div className="span">Recipe Title *</div>
          <Input
            type="text"
            className={`${formErrors.title && 'error-field'}`}
            name="title"
            value={formValues.title}
            onChange={handleChange}
            required
          />
          {formErrors.title && (
            <em className="error-feedback">{formErrors.title}</em>
          )}
        </label>
        <label htmlFor="newIngredient">
          <div className="span">Recipe Ingredients</div>
        </label>
        <div className="ingredients">
          <Input
            type="text"
            id="newIngredient"
            name="newIngredient"
            value={formValues.newIngredient}
            onChange={handleChange}
            ref={ingredientInput}
          />
          <button
            className="btn"
            onClick={handleAdd}
            style={{ backgroundColor: color }}
          >
            + add
          </button>
        </div>
        <p style={{ fontWeight: '300' }}>
          {formValues.ingredients.length > 0 && 'Current ingredients: '}
          {formValues.ingredients.length > 0 &&
            formValues.ingredients.map((ing) => (
              <em
                className="ing-tag"
                key={ing}
                onClick={(e) => handleRemove(e, ing)}
              >
                {ing.toLowerCase()} &times;
              </em>
            ))}
        </p>
        <label>
          <div className="span">Recipe Method *</div>
          {/* <Textarea
            type="text"
            className={`${formErrors.method && 'error-field'}`}
            name="method"
            value={formValues.method}
            onChange={handleChange}
            rows={6}
            required
          /> */}
          <SimpleMdeReact
            value={formValues.method}
            onChange={onChangeMethod}
            className={`${formErrors.method && 'error-field'}`}
          />
          {formErrors.method && (
            <em
              className="error-feedback"
              style={{ position: 'relative', top: '-30px' }}
            >
              {formErrors.method}
            </em>
          )}
        </label>
        <label>
          <div className="span">Cooking Time (minutes) *</div>
          <Input
            type="text"
            className={`${formErrors.cookingTime && 'error-field'}`}
            name="cookingTime"
            value={formValues.cookingTime}
            onChange={handleChange}
            required
          />
          {formErrors.cookingTime && (
            <em className="error-feedback">{formErrors.cookingTime}</em>
          )}
        </label>
        <button className="btn" style={{ backgroundColor: color }}>
          Add Recipe
        </button>
      </form>
    </div>
  )
}

export default Create
