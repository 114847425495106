import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

// styles
import './Register.css'

// utils
import { togglePassword } from '../../utils'

// hooks
import { useTheme } from '../../hooks/useTheme'
import { useRegister } from '../../hooks/useRegister'

const Input = styled.input`
  &:focus {
    border-color: ${(props) => props.theme.color} !important;
  }
`

const Register = () => {
  const { color } = useTheme()

  const initialValues = {
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  }
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)

  const { register, isPending, error } = useRegister()

  const _registerRef = useRef(register).current
  const _registerCb = useCallback(() => {
    setIsSubmit(false)

    _registerRef(
      formValues.name.trim(),
      formValues.email.trim(),
      formValues.password
    )
  }, [formValues.name, formValues.email, formValues.password, _registerRef])

  // handle form fields changes
  const handleChange = (e) => {
    let { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  // validate form
  const validate = (values) => {
    const errors = {}
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i

    if (!values.email.trim()) {
      errors.email = 'Email is required'
    } else if (!regex.test(values.email.trim())) {
      errors.email = 'Email is not valid'
    }

    if (!values.password) {
      errors.password = 'Password is required'
    } else if (values.password.length < 6) {
      errors.password = 'Password must be more than 6 characters'
    } else if (values.password.length > 10) {
      errors.password = 'Password cannot exceed more than 10 characters'
    }

    if (values.confirmPassword !== values.password)
      errors.confirmPassword = 'Passwords not match'

    return errors
  }

  // handle form submission
  const handleSubmit = (e) => {
    e.preventDefault()

    setFormErrors(validate(formValues))
    setIsSubmit(true)
  }

  // handle show/hide password
  const handleShowHidePassword = (e) => {
    const passwordField =
      e.currentTarget.parentElement.getElementsByTagName('input')[0]
    const eyeIcon = e.currentTarget
    togglePassword(passwordField, eyeIcon)
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // register user once the form is valid
      _registerCb()

      // reset form
      setFormValues({ name: '', email: '', password: '', confirmPassword: '' })
    }
  }, [formErrors, isSubmit, _registerCb])

  return (
    <>
      <div className="register">
        <h2 className="page-title">Register</h2>

        <form onSubmit={handleSubmit} noValidate>
          <label>
            <span>Name</span>
            <Input
              type="text"
              className=""
              name="name"
              value={formValues.name}
              onChange={handleChange}
            />
          </label>
          <label>
            <span>Email *</span>
            <Input
              type="text"
              className={`${formErrors.email && 'error-field'}`}
              name="email"
              value={formValues.email}
              onChange={handleChange}
            />
            {formErrors.email && (
              <i className="error-feedback">{formErrors.email}</i>
            )}
          </label>
          <label>
            <span>Password *</span>
            <div className="password-block">
              <Input
                type="password"
                autoComplete="new-password"
                className={`${formErrors.password && 'error-field'}`}
                name="password"
                value={formValues.password}
                onChange={handleChange}
              />
              <i className="eye" onClick={handleShowHidePassword}>
                hide
              </i>
            </div>
            {formErrors.password && (
              <em className="error-feedback">{formErrors.password}</em>
            )}
          </label>
          <label>
            <span>Confirm Password *</span>
            <div className="password-block">
              <Input
                type="password"
                autoComplete="new-password"
                className={`${formErrors.confirmPassword && 'error-field'}`}
                name="confirmPassword"
                value={formValues.confirmPassword}
                onChange={handleChange}
              />
              <i className="eye" onClick={handleShowHidePassword}>
                hide
              </i>
            </div>
            {formErrors.confirmPassword && (
              <em className="error-feedback">{formErrors.confirmPassword}</em>
            )}
          </label>
          {isPending ? (
            <button className="button" style={{ backgroundColor: color }}>
              Loading...
            </button>
          ) : (
            <button className="button" style={{ backgroundColor: color }}>
              Register
            </button>
          )}
        </form>
      </div>
      <p className="register-help">
        Already have an account?{' '}
        <Link to="/login" className="dotted" style={{ color: color }}>
          Login
        </Link>
      </p>
    </>
  )
}

export default Register
