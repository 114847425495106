import React from 'react'
import Shimmer from './Shimmer'
import SkeletonElement from './SkeletonElement'

const SkeletonItem = ({ theme, visibility }) => {
  return (
    <div className={`skeleton-wrapper ${theme} ${visibility}`}>
      <div className="skeleton-article">
        <div className="card">
          <SkeletonElement
            type="title skeleton-t-space-5"
            width="skeleton-w-70"
          />
          <SkeletonElement type="subtitle" width="skeleton-w-60" />
          <div>
            <SkeletonElement type="text" width="skeleton-w-90" />
            <SkeletonElement type="text" width="skeleton-w-100" />
            <SkeletonElement type="text" width="skeleton-w-70" />
          </div>
          <div className="skeleton-center">
            <SkeletonElement type="button" space="skeleton-b-space-5" />
          </div>
        </div>
      </div>
      <Shimmer />
    </div>
  )
}

SkeletonItem.defaultProps = {
  theme: 'transparent',
  visibility: ''
}

export default SkeletonItem
