import React, { useEffect } from 'react'

// styles
import './ConfirmBox.css'

const ConfirmBox = ({ title, message, show, handleHide, handleAction }) => {
  useEffect(() => {
    if (show) {
      document.querySelector('.confirm-bg').style.display = 'flex'
      document.querySelector('.confirm-box').style.display = 'flex'
    } else {
      document.querySelector('.confirm-bg').style.display = 'none'
      document.querySelector('.confirm-box').style.display = 'none'
    }
  }, [show])

  return (
    <>
      <div className="confirm-bg" onClick={handleHide}></div>
      <div className="confirm-box fadeIn">
        <div className="confirm-box-header">
          <span className="title">{title}</span>
          <span className="close" onClick={handleHide}>
            &times;
          </span>
        </div>
        <div className="confirm-box-body">{message}</div>
        <div className="confirm-box-footer">
          <button className="cancel-button" onClick={handleHide}>
            Cancel
          </button>
          <button className="confirm-button" onClick={handleAction}>
            Confirm
          </button>
        </div>
      </div>
    </>
  )
}

export default ConfirmBox
