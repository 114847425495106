import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

// styles
import './Login.css'

// utils
import { togglePassword } from '../../utils'

// hooks
import { useTheme } from '../../hooks/useTheme'
import { useLogin } from '../../hooks/useLogin'

const Input = styled.input`
  &:focus {
    border-color: ${(props) => props.theme.color} !important;
  }
`

const Login = () => {
  const { color } = useTheme()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isSubmit, setIsSubmit] = useState(false)

  const { login, isPending, error } = useLogin()
  const _loginRef = useRef(login).current

  const _loginCb = useCallback(() => {
    setIsSubmit(false)

    _loginRef(email.trim(), password)
  }, [email, password, _loginRef])

  const handleSubmit = (e) => {
    e.preventDefault()

    setIsSubmit(true)
  }

  // handle show/hide password
  const handleShowHidePassword = (e) => {
    const passwordField =
      e.currentTarget.parentElement.getElementsByTagName('input')[0]
    const eyeIcon = e.currentTarget
    togglePassword(passwordField, eyeIcon)
  }

  useEffect(() => {
    if (isSubmit) {
      _loginCb()
    }
  }, [isSubmit, _loginCb])

  return (
    <>
      <div className="login">
        <h2 className="page-title">Login</h2>

        <form onSubmit={handleSubmit} noValidate>
          <label>
            <span>Email</span>
            <Input
              type="text"
              className=""
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label>
            <span>Password</span>
            <div className="password-block">
              <Input
                type="password"
                autoComplete="new-password"
                className=""
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <i className="eye" onClick={handleShowHidePassword}>
                hide
              </i>
            </div>
          </label>
          {isPending ? (
            <button className="button" style={{ backgroundColor: color }}>
              Loading...
            </button>
          ) : (
            <button className="button" style={{ backgroundColor: color }}>
              Login
            </button>
          )}
        </form>
      </div>
      <p className="register-help">
        Need an account?{' '}
        <Link to="/register" className="dotted" style={{ color: color }}>
          Register
        </Link>
      </p>
    </>
  )
}

export default Login
