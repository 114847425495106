import { useContext } from 'react'

// context
import { ThemeContext } from '../context/ThemeContext'

const useTheme = () => {
  const context = useContext(ThemeContext)

  if (context === undefined) {
    throw new Error('useTheme() must be used inside a ThemeProvider.')
  }

  return context
}

export { useTheme }
