import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import removeMd from 'remove-markdown'

// styles
import './RecipeList.css'

// services
import { removeRecipe } from '../services/recipeService'

// icons
import { penIcon, trashIcon } from '../icons'

// components
import ConfirmBox from '../components/ConfirmBox'

// context
import { useAuthContext } from '../hooks/useAuthContext'

const RecipeList = ({ recipes }) => {
  const navigate = useNavigate()

  const { user } = useAuthContext()

  const [show, setShow] = useState(false)
  const [selectedRecipe, setSelectedRecipe] = useState(null)
  const handleHide = () => setShow(false)
  const handleShow = (id) => {
    setSelectedRecipe(id)
    setShow(true)
  }

  const handleEdit = (id) => {
    navigate(`/recipe/edit/${id}`)
  }

  const handleRemove = async () => {
    setShow(false)
    await removeRecipe(selectedRecipe)
    window.location.reload()

    toast.success('Recipe removed successfully!')
  }

  if (recipes.length === 0) {
    return <p className="empty">Sorry, no recipes found!</p>
  }

  return (
    <>
      <div className="recipe-list">
        {recipes.map((recipe) => (
          <div key={recipe.id} className="card">
            <h3>{recipe.title}</h3>
            <p className="subheading">{recipe.cookingTime} minutes to make</p>
            {/* <div>{recipe.method.substring(0, 100)}...</div> */}
            <div>{removeMd(recipe.method.substring(0, 100))}...</div>
            <Link to={`/recipe/${recipe.id}`}>Cook This</Link>
            {user && user.uid === recipe.uid && (
              <>
                <img
                  src={penIcon}
                  className="action pen"
                  alt="edit"
                  title="edit"
                  onClick={() => handleEdit(recipe.id)}
                />
                <img
                  src={trashIcon}
                  className="action trash"
                  alt="remove"
                  title="remove"
                  onClick={() => handleShow(recipe.id)}
                />
              </>
            )}
          </div>
        ))}
      </div>
      <ConfirmBox
        title="Delete Recipe"
        message="Are you sure you wish to remove this recipe?"
        show={show}
        handleHide={handleHide}
        handleAction={handleRemove}
      />
    </>
  )
}

export default RecipeList
