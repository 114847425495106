import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'

// firebase
import { projectAuth } from '../firebase/config'

// context
import { useAuthContext } from './useAuthContext'

// alert
import { errorMsg, successMsg } from '../alerts'

export const useLogin = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const location = useLocation()
  const navigate = useNavigate()

  const login = async (email, password) => {
    setError(null)
    setIsPending(true)

    // sign the user in
    try {
      const res = await projectAuth.signInWithEmailAndPassword(email, password)

      if (!res) throw new Error(errorMsg.loggedIn)

      // dispatch login action
      dispatch({ type: 'LOGIN', payload: res.user })

      // update state
      if (!isCancelled) {
        // console.log(res.user)

        setIsPending(false)
        setError(null)

        // alert
        toast.dismiss()
        toast.success(successMsg.loggedIn.replace('Firebase: ', ''))

        // navigate
        if (location.state) {
          navigate(location.state.from.pathname)
        } else {
          navigate('/')
        }
      }
    } catch (err) {
      if (!isCancelled) {
        // console.log(err.message)

        // update state
        setError(err.message)
        setIsPending(false)

        // alert
        toast.dismiss()
        toast.error(err.message.replace('Firebase: ', ''), { autoClose: 20000 })
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { login, error, isPending }
}
