// firebase
import { projectFirestore } from '../firebase/config'

/**
 * @desc Retrieve the collection size
 * @param {String} collection - Collection name
 * @returns {Number} Size of colllection
 */
const getRecipeSize = async () => {
  const doc = await projectFirestore.collection('recipes').get()
  return doc.size
}

/**
 * @desc Retrieve my collection size
 * @param {String} collection - Collection name
 * @returns {Number} Size of colllection
 */
const getMyRecipeSize = async (uid) => {
  const doc = await projectFirestore
    .collection('recipes')
    .where('uid', '==', uid)
    .get()
  return doc.size
}

/**
 * @desc Get recipes in real time includes pagination option
 * @param {Number} perPage The numbe of recipes shown per page. Set perPage = -1 to ignore pagination
 * @param {String} offset The field content we sort by
 * @param {String} nav The navigation options next or prev
 * @param {Observer} observer A single object containing next and error callbacks.
 * @returns {Unsubscribe} Unsubscribe function that can be called to cancel the snapshot listener.
 */
const getAllRecipes = (perPage, offset, nav, observer) => {
  const preQuery = projectFirestore.collection('recipes').orderBy('titleLower')

  if (perPage === -1) {
    return preQuery.onSnapshot(observer)
  } else {
    if (nav === 'next') {
      return preQuery.startAfter(offset).limit(perPage).onSnapshot(observer)
    } else {
      return preQuery
        .endBefore(offset)
        .limitToLast(perPage)
        .onSnapshot(observer)
    }
  }
}

/**
 * @desc Get my recipes in real time includes pagination option
 * @param {Number} perPage The numbe of recipes shown per page. Set perPage = -1 to ignore pagination
 * @param {String} offset The field content we sort by
 * @param {String} nav The navigation options next or prev
 * @param {Observer} observer A single object containing next and error callbacks.
 * @returns {Unsubscribe} Unsubscribe function that can be called to cancel the snapshot listener.
 */
const getMyRecipes = (perPage, offset, nav, uid, observer) => {
  const preQuery = projectFirestore
    .collection('recipes')
    .where('uid', '==', uid)
    .orderBy('titleLower')

  if (perPage === -1) {
    return preQuery.onSnapshot(observer)
  } else {
    if (nav === 'next') {
      return preQuery.startAfter(offset).limit(perPage).onSnapshot(observer)
    } else {
      return preQuery
        .endBefore(offset)
        .limitToLast(perPage)
        .onSnapshot(observer)
    }
  }
}

/**
 * @desc Get recipe by id
 * @param {String} id
 * @returns {Promise} A Promise resolved with an ID containing the current recipe contents.
 */
const getRecipeById = async (id) => {
  return await projectFirestore.collection('recipes').doc(id).get()
}

/**
 * @desc Add a recipe
 * @param {Object} recipe An Object containing the data for the new recipe.
 * @returns {Promise} A Promise resolved with an ID pointing to the newly created recipe after it has been written to the backend.
 */
const addRecipe = async (recipe) => {
  return await projectFirestore.collection('recipes').add(recipe)
}

/**
 * @desc Update a recipe
 * @param {Object} updatedRecipe An object containing the fields and values with which to update the recipe. Fields can contain dots to reference nested fields within the recipe.
 * @returns {Promise} A Promise resolved once the data has been successfully written to the backend (Note that it won't resolve while you're offline).
 */
const updateRecipe = async (id, updatedRecipe) => {
  return await projectFirestore
    .collection('recipes')
    .doc(id)
    .update(updatedRecipe)
}

/**
 * @desc Remove recipe
 * @param {String} id
 * @returns {Promise} A Promise resolved once the recipe has been successfully deleted from the backend (Note that it won't resolve while you're offline).
 */
const removeRecipe = async (id) => {
  return await projectFirestore.collection('recipes').doc(id).delete()
}

/**
 * @desc Search for recipe in real time
 * @param {Observer} observer A single object containing next and error callbacks.
 * @returns {Unsubscribe} Unsubscribe function that can be called to cancel the snapshot listener.
 */
const searchForRecipe = (term, observer) => {
  return projectFirestore
    .collection('recipes')
    .orderBy('titleLower')
    .startAt(term.toLowerCase())
    .endAt(term.toLowerCase() + '\uf8ff')
    .onSnapshot(observer)
}

export {
  getRecipeSize,
  getMyRecipeSize,
  getAllRecipes,
  getMyRecipes,
  getRecipeById,
  addRecipe,
  updateRecipe,
  removeRecipe,
  searchForRecipe
}
