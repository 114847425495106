import React, { useEffect, useRef } from 'react'
import { Link, NavLink } from 'react-router-dom'

// styles
import './Navbar.css'

// components
import Searchbar from './Searchbar'

// hooks
import { useTheme } from '../hooks/useTheme'
import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'

const Navbar = () => {
  const createRepiceBtn = useRef(null)

  const { color, mode } = useTheme()
  const { logout } = useLogout()
  const { user } = useAuthContext()

  const handleLogout = (e) => {
    e.preventDefault()
    logout()
  }

  useEffect(() => {
    const burger = document.querySelector('#burger')
    const menu = document.querySelector('#menu')

    burger.addEventListener('click', (e) => {
      e.preventDefault()
      if (menu.classList.contains('hidden-small')) {
        menu.classList.remove('hidden-small')
        burger.innerHTML = 'CLOSE'
      } else {
        menu.classList.add('hidden-small')
        burger.innerHTML = 'MENU'
      }
    })

    // createRepiceBtn.current.addEventListener('click', () => {
    //   menu.classList.add('hidden-small')
    //   burger.innerHTML = 'MENU'
    // })

    document.addEventListener('click', (e) => {
      if (e.target.classList.contains('nav-item')) {
        menu.classList.add('hidden-small')
        burger.innerHTML = 'MENU'
      }
    })
  }, [])

  return (
    <>
      {user && (
        <div className="authBar">
          <div>
            <Link
              to="/my-recipes"
              className="dotted"
              style={{ color: color, marginRight: '15px' }}
            >
              My Recipes
            </Link>
          </div>
          <div>
            Hi, {user.displayName ? user.displayName : 'Guest'}
            <Link
              to="/login"
              className="dotted"
              style={{ color: color, marginLeft: '15px' }}
              onClick={handleLogout}
            >
              Logout
            </Link>
          </div>
        </div>
      )}
      <header className="header" style={{ backgroundColor: color }}>
        <div className="navbar">
          <div className="navbar-brand">
            <Link to="/" className="brand">
              Cooking
            </Link>
            <button className="burger" id="burger">
              MENU
            </button>
          </div>
          <div className="searchbar-desktop">
            <Searchbar />
          </div>
          <div className="navbar-menu">
            <nav className="hidden-small" id="menu">
              <ul className="nav-list">
                <li className="searchbar-mobile">
                  <Searchbar />
                </li>
                <li>
                  {user && (
                    <NavLink
                      to="/create"
                      className="nav-item"
                      ref={createRepiceBtn}
                    >
                      Create Recipe
                    </NavLink>
                  )}
                  {!user && (
                    <NavLink
                      to="/login"
                      className="nav-item"
                      ref={createRepiceBtn}
                    >
                      Login
                    </NavLink>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  )
}

export default Navbar
