import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

// styles
import './Searchbar.css'

// icons
import { searchIcon } from '../icons'

const Searchbar = () => {
  const [term, setTerm] = useState('')
  const searchInput = useRef(null)

  const navigate = useNavigate()
  const [search] = useSearchParams()

  const selectedTerm = search.get('q') || ''

  const handleSubmit = (e) => {
    e.preventDefault()

    if (term === '') return navigate('/')

    navigate(`/search?q=${term.trim()}`)
  }

  useEffect(() => {
    const burger = document.querySelector('#burger')
    const menu = document.querySelector('#menu')

    searchInput.current.onkeydown = (event) => {
      if (event.key === 'Enter') {
        menu.classList.add('hidden-small')
        burger.innerHTML = 'MENU'
      }
    }

    setTerm(selectedTerm.trim())
  }, [selectedTerm])

  return (
    <div className="searchbar">
      <form onSubmit={handleSubmit} noValidate>
        <input
          type="text"
          className="search-input"
          id="search"
          placeholder="Search for recipe"
          ref={searchInput}
          value={term}
          onChange={(e) => setTerm(e.target.value)}
          required
        />
        <img src={searchIcon} className="search-icon" alt="..." />
      </form>
    </div>
  )
}

export default Searchbar
