import React, { useCallback, useEffect, useState, useRef } from 'react'

// styles
import './Home.css'

// services
import { getRecipeSize, getAllRecipes } from '../../services/recipeService'

// components
import RecipeList from '../../components/RecipeList'
import Pagination from '../../components/Pagination'
import SkeletonItem from '../../components/skeletons/SkeletonItem'

// pagination
const PER_PAGR = Number(process.env.REACT_APP_ITEMS_PER_PAGE) || 6

const Home = () => {
  const [data, setData] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState(null)

  const startMetaValues = {
    totalItems: 0,
    itemsPerPage: PER_PAGR,
    totalPages: 0,
    page: 1,
    offset: '',
    nav: 'next',
    fromPage: 0,
    toPage: 0,
    start: '',
    end: ''
  }
  const [meta, setMeta] = useState(startMetaValues)
  const isMetaInit = useRef(false)

  const initPaging = useCallback(async () => {
    const totalItems = await getRecipeSize('recipes')

    setMeta((prevMeta) => {
      return { ...prevMeta, totalItems }
    })

    const totalPages =
      PER_PAGR === -1 ? 1 : Math.ceil(Number(totalItems) / PER_PAGR)
    const itemsPerPage = PER_PAGR === -1 ? Number(totalItems) : PER_PAGR
    const fromPage = (meta.page - 1) * itemsPerPage + 1
    const toPage =
      meta.page * itemsPerPage > totalItems
        ? totalItems
        : meta.page * itemsPerPage

    setMeta((prevMeta) => {
      return {
        ...prevMeta,
        totalPages,
        itemsPerPage,
        page: meta.page,
        fromPage,
        toPage
      }
    })
  }, [meta.page])

  const updatePaging = useCallback(() => {
    setIsPending(true)

    const unsub = getAllRecipes(
      meta.itemsPerPage,
      meta.offset,
      meta.nav,
      (snapshot) => {
        if (snapshot.empty) {
          setError('No recipes found!')
          setIsPending(false)
        } else {
          let results = []

          snapshot.docs.forEach((doc) => {
            results.push({ id: doc.id, ...doc.data() })
          })
          setData(results)
          setIsPending(false)

          setMeta((prevMeta) => {
            return {
              ...prevMeta,
              start: results[0].titleLower,
              end: results[results.length - 1].titleLower
            }
          })
        }
      },
      (err) => {
        setError(err.message)
        setIsPending(false)
      }
    )

    return { unsub }
  }, [meta.itemsPerPage, meta.nav, meta.offset])

  const handlePaging = (e, page, offset, nav) => {
    e.preventDefault()
    setMeta({ ...meta, page, offset, nav })
  }

  useEffect(() => {
    // if (!isMetaInit.current) {
    //   setMeta((prevMeta) => {
    //     return { ...prevMeta, page: 1 }
    //   })
    //   isMetaInit.current = true
    // }
    initPaging()

    const { unsub } = updatePaging()

    return () => {
      unsub()
    }
  }, [initPaging, updatePaging])

  return (
    <>
      {/* <pre style={{ padding: '20px', fontWeight: '300' }}>
        meta: {JSON.stringify(meta, undefined, 2)}
      </pre> */}
      <div className="home">
        {data && (
          <div className="paging-header fw-normal">
            Showing {meta.fromPage} to {meta.toPage} of {meta.totalItems}{' '}
            {meta.totalItems === 1 ? 'recipe' : 'recipes'}
          </div>
        )}
        {/* {isPending && (
          <div className="loading">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )} */}
        {isPending && (
          <div className="recipe-list">
            <SkeletonItem />
            <SkeletonItem visibility="skeleton-hide-md" />
            <SkeletonItem visibility="skeleton-hide-lg" />
          </div>
        )}
        {error && <p className="error">{error}</p>}
        {data && !isPending && (
          <>
            <RecipeList recipes={data} />
            {meta.totalPages > 1 && (
              <Pagination
                page={meta.page}
                pages={meta.totalPages}
                showPrevious={(e) =>
                  handlePaging(e, meta.page - 1, meta.start, 'prev')
                }
                showNext={(e) =>
                  handlePaging(e, meta.page + 1, meta.end, 'next')
                }
              />
            )}
          </>
        )}
      </div>
    </>
  )
}

export default Home
